<template>
  <div class="prescription-model-view">
    <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
    <app-header icon="table" :title="pageTitle" goes-back></app-header>

    <section>
      <div class="card">
        <div class="card-content">
          <form @submit.prevent="onSubmit">
            <div class="row">
              <div class="col-6">
                <app-input
                  label="Nome"
                  v-model="model.name"
                  is-required
                ></app-input>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12">
                <b-field label="Prescrição" class="required">
                  <editor
                    v-model="model.prescription"
                    :api-key="editorApiKey"
                  ></editor>
                </b-field>
              </div>
            </div>

            <div class="row"></div>

            <div class="row mt-5">
              <div class="col-12 has-text-right">
                <b-button
                  @click="onSubmit()"
                  :loading="isSaving"
                  :disabled="isSaving || !isValid"
                  type="is-primary"
                >
                  Salvar
                </b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import PrescriptionService from '@/services/prescriptions.service';

export default {
  components: {
    editor: Editor,
  },
  data: () => ({
    editorApiKey: process.env.VUE_APP_TINY_MCE_KEY,
    isLoading: false,
    isSaving: false,
    modelId: null,
    model: {},
  }),
  computed: {
    pageTitle() {
      return `${this.modelId ? 'Editar' : 'Cadastrar'} Modelo de Receituário`;
    },
    isValid() {
      const { name, prescription } = this.model;
      return name && prescription;
    },
  },
  methods: {
    onSubmit() {
      this.isSaving = true;

      const promise = this.modelId
        ? PrescriptionService.updateModel(this.modelId, this.model)
        : PrescriptionService.storeModel(this.model);

      promise
        .then(() => {
          this.$buefy.snackbar.open('Modelo salvo com sucesso.');
          this.$router.go(-1);
        })
        .catch(() => {
          this.$buefy.snackbar.open('Erro ao tentar salvar modelo.');
          this.isSaving = false;
        });
    },
    loadModel(id) {
      this.isLoading = true;

      PrescriptionService.getModel(id)
        .then(({ data }) => (this.model = data))
        .catch(() =>
          this.$buefy.snackbar.open('Erro ao tentar carregar modelo.')
        )
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.modelId = Number(this.$route.params.id);
    this.modelId && this.loadModel(this.modelId);
  },
};
</script>
